<template>
    <div>
      <h4 class="mt-4">CA Login Access Queue</h4>
      <section class="row mt-5 d-flex">
        <div class="col-4 col-lg">
          <label for="started-at">Started at</label>
          <select id="started-at" class="form-control" v-model="startedAt">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_7_days">Last 7 days</option>
            <option value="this_month">This month</option>
            <option value="this_year">This year</option>
          </select>
        </div>
        <div class="col-4 col-lg">
          <label for="updated-at">Updated at</label>
          <select id="updated-at" class="form-control" v-model="updatedAt">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_7_days">Last 7 days</option>
            <option value="this_month">This month</option>
            <option value="this_year">This year</option>
          </select>
        </div>
        <div class="col-4 col-lg">
          <label for="state">State</label>
          <select id="state" class="form-control" v-model="state">
            <option value="">All</option>
            <option :value="state" v-for="state in statesWithAutomation" :key="state">{{ state }}</option>
          </select>
        </div>
        <div class="col-4 col-lg">
          <label for="status">Status</label>
          <select id="status" class="form-control" v-model="status">
            <option value="">All</option>
            <option value="created">Created</option>
            <option value="started">Started</option>
            <option value="processing">Processing</option>
            <option value="success">Success</option>
            <option value="failed">Failed</option>
          </select>
        </div>
        <div class="col-4 col-lg">
          <label for="state">Substate</label>
          <select id="state" class="form-control" v-model="state">
            <option value="">All</option>
            <option :value="state" v-for="state in substatesWithAutomation" :key="state">{{ state }}</option>
          </select>
        </div>
        <div class="col-4 col-lg-2 d-flex align-items-end">
          <ZgButton class="zg-btn-default fullwidth" @click="search">
            <template v-slot:title>
              <i class="fas fa-search"></i>&nbsp;Search
            </template>
          </ZgButton>
        </div>
      </section>
      <section class="table-responsive mt-4">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Entity Name</th>
              <th>Agency Login Account</th>
              <th>Attempts</th>
              <th>Status</th>
              <th>Substatus</th>
              <th>Details</th>
              <th>Created at</th>
              <th>Updated at</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="100%">
                <div class="text-center my-4" >
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="!itemsQueueSorted.length && !isLoading">
              <td colspan="100%">
                <h4 class="text-center my-4">No items in queue available</h4>
              </td>
            </tr>
            <tr v-for="(itemQueue, index) in itemsQueueSorted" :key="index">
              <td class="text-break">{{ itemQueue.business_entity.business_entity_name }}</td>
              <td>{{ itemQueue.primary_agency_login_account.username }}</td>
              <td class="text-center">{{ itemQueue.attempts }}</td>
              <td>{{ itemQueue.status }}</td>
              <td>{{ itemQueue.substatus }}</td>
              <td>{{ itemQueue.details }}</td>
              <td>{{ itemQueue.created_datetime | moment('MM/DD/YYYY h:mma') }}</td>
              <td>{{ itemQueue.updated_datetime | moment('MM/DD/YYYY h:mma') }}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <div class="d-flex flex-row mt-4 mb-5">
        <div class="col-6">
          <div class="row">
            <div class="col d-flex justify-content-center">
              <h6>Actions</h6>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-center">
              <ZgButton class="zg-btn-default zg-text-sm" @click="startRobot" :isEnable="hasPermission('automation:processor')">
                <template v-slot:title>Start CA Agency Login Sharing&nbsp;<i class="fa fa-cogs" aria-hidden="true"></i></template>
              </ZgButton>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row py-2">
            <div class="col d-flex justify-content-center">
              <ZgButton class="zg-btn-outline zg-btn-no-bg zg-text-sm" @click="previousPage" :isEnable="!!itemsQueue.previous">
                <template v-slot:title>Previous</template>
              </ZgButton>
              <span class="mx-3 pt-2">
                <p class="p-2" v-if="totalPages">{{ page }} - {{ totalPages }}</p>
                <p class="p-2" v-else> - </p>
              </span>
              <ZgButton class="zg-btn-outline zg-btn-no-bg zg-text-sm" @click="nextPage"  :isEnable="!!itemsQueue.next">
                <template v-slot:title>Next</template>
              </ZgButton>
            </div>
          </div>
          <div class="row py-2">
            <div class="col d-flex justify-content-center">
              <p>Total results: {{ this.itemsQueue.count }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { hasPermissionMixin, automatedFilingMixin, userInformationMixin } from '@/components/mixins';
import { AUTOMATION_STATUSES, QUEUE_LOGIN_ITEMS_SUBSTATUS } from '@/assets/constants/automations';
import ZgButton from '@/components/common/Generics/ZgButton.vue';

export default {
    name: "LoginAccessQueue",
    mixins: [hasPermissionMixin, automatedFilingMixin, userInformationMixin],
    data() {
        return {
            isLoading: true,
            showTracking: false,
            itemsQueue: {},
            page: 1,
            startedAt: "today",
            updatedAt: "today",
            state: "",
            status: "",
            substatus: "",
            AUTOMATION_STATUSES,
            QUEUE_LOGIN_ITEMS_SUBSTATUS
        };
    },
    computed: {
        itemsQueueSorted() {
          if (this.itemsQueue.results) {
            return [...this.itemsQueue.results];
          }
          return [];
        },
        statesWithAutomation() {
          return [
              "CA",
          ];
        },
        substatesWithAutomation() {
          return Array.from(Object.values(QUEUE_LOGIN_ITEMS_SUBSTATUS));
        },
        totalPages() {
          return Math.ceil(this.itemsQueue.count / 10);
        },
    },
    methods: {
        ...mapActions("automation", ["fetchItemsQueue", "startRobotCAAgencyLoginAccountSharing"]),
        getItemsQueue() {
            this.isLoading = true;
            this.itemsQueue = [];
            this.fetchItemsQueue({
                page: this.page,
                service_type: this.serviceType,
                created_at: this.startedAt,
                updated_at: this.updatedAt,
                jurisdiction: this.state,
                status: this.status,
                substatus: this.sustatus,
            })
                .then((response) => { this.itemsQueue = response !== undefined ? response : {};})
                .finally(() => { this.isLoading = false; });
        },
        search() {
            this.page = 1;
            this.getItemsQueue();
        },
        nextPage() {
            this.page += 1;
            this.getItemsQueue();
        },
        previousPage() {
            this.page -= 1;
            this.getItemsQueue();
        },
        startRobot() {
          this.startRobotCAAgencyLoginAccountSharing()
            .finally((response) => {
              if(response) {
                this.getItemsQueue();
              }
            })
        }
    },
    mounted() {
        this.startedAt = this.$route.query.started_at ? this.$route.query.started_at : "today";
        this.state = this.$route.query.state ? this.$route.query.state : "";
        this.status = this.$route.query.status ? this.$route.query.status : "";
        this.substatus = this.$route.query.substatus ? this.$route.query.substatus : "";
        if (Object.keys(this.$route.query).length > 0) {
            this.startedBy = "";
        }
        this.getItemsQueue();
    },
    components: { ZgButton }
};
</script>

<style lang="scss" scoped>
  .table-responsive {
    max-height: 80vh;
  }
  .status {
    word-break: break-all;
  }
</style>
