<template>
  <div @click="redirectToAutomatedFilingsView" class="automation-status-item">
    <div class="d-flex flex-column" :name="'externalTrigger' + automatedStatus.state">
      <h2 :class="{'header': true,'bg-success': automatedStatus.rpa_status === 'NO_ISSUES','bg-warning': automatedStatus.rpa_status === 'FEW_ISSUES','bg-danger': automatedStatus.rpa_status === 'MANY_ISSUES'}">
        {{ this.stateObj[automatedStatus.state] }} ({{ automatedStatus.state }})
        <i class="fas fa-check-circle ms-2" v-if="automatedStatus.rpa_status === 'NO_ISSUES'"></i>
        <i class="fas fa-exclamation-triangle ms-2" v-if="automatedStatus.rpa_status === 'FEW_ISSUES'"></i>
        <i class="fas fa-times-circle ms-2" v-if="automatedStatus.rpa_status === 'MANY_ISSUES'"></i>
      </h2>
      <section class="box">
        <p>
          {{ automatedStatus.total }} processes ({{ automatedStatus.success }} success / {{ automatedStatus.non_success }} non-success / {{ automatedStatus.in_progress }} in progress)
        </p>
        <p>
          <b> Avg time: </b> {{ getDuration(automatedStatus.avg_completion_time) }} <br>
          <b> Min time: </b> {{ getDuration(automatedStatus.min_completion_time) }} <br>
          <b> Max time: </b> {{ getDuration(automatedStatus.max_completion_time) }}
        </p>
        <p v-if="automatedStatus.last_started_proccess_date">
          <b>Last started at:</b> {{ automatedStatus.last_started_proccess_date | moment("MM/DD/YY HH:mm") }}
        </p>
        <p v-else>
          <b>Last started at:</b> No processes running
        </p>
      </section>
    </div>
    <tippy :to="'externalTrigger' + automatedStatus.state">
      <h5 class="mb-2">Status consolidated</h5>
      <div class="text-left d-flex flex-column">
        <p class="mb-1 p-0 text-white" v-for="consolidated in automatedStatus.status_consolidated" :key="consolidated.status">
          {{ consolidated.status }}: <b>{{ consolidated.total }}</b>
        </p>
      </div>
    </tippy>
  </div>
</template>

<script>

import { stateObj } from '@/components/utils/global-utils';

export default {
  name: 'AutomationStatusItem',

  props: ['automatedStatus', 'serviceType', 'startedAt'],

  data() {
    return {
      stateObj
    };
  },

  methods: {
    getDuration(seconds) {
      return new Date(seconds * 1000).toISOString().substr(14, 5);
    },
    redirectToAutomatedFilingsView() {
      const route = this.$router.resolve({
        name: 'AutomatedFilings',
        query: { service_type: this.serviceType, started_at: this.startedAt, state: this.automatedStatus.state },   
      });
      window.open(route.href, '_blank');
    },
  }
};
</script>

<style lang="scss" scoped>
  .automation-status-item {
    cursor: pointer;
  }
</style>
