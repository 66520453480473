<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Automated Filing Tracking</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <th>Try</th>
              <th>Started by</th>
              <th>Type</th>
              <th>Test</th>
              <th>Initial Status</th>
              <th>Final Status</th>
              <th>Sub status</th>
              <th>Start</th>
              <th>End</th>
              <th>Payment details</th>
              <th>Error details</th>
            </thead>
            <tbody>
              <tr v-for="(track, index) in automatedFilingTracking" :key="index">
                <td>{{ track.num_try }}</td>
                <td v-html="addSuggestedBreakToEmail(track.started_by)"></td>
                <td>{{ track.type }}</td>
                <td>{{ track.is_test }}</td>
                <td>{{ makeSentenceCase(track.initial_status) }}</td>
                <td>{{ makeSentenceCase(track.final_status) }}</td>
                <td>{{ track.sub_status }}</td>  
                <td>{{ track.started_datetime | moment("MM/DD/YY HH:mm") }}</td>
                <td>{{ track.finished_datetime | moment("MM/DD/YY HH:mm") }}</td>
                <td>
                  {{ makeSentenceCase(track.payment_method) }} <br>
                  {{ makeSentenceCase(track.payment_details) }}
                </td>
                <td>{{ makeSentenceCase(track.error_details) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addSuggestedBreakToEmail, makeSentenceCase } from '@/assets/utils/text-utils';
import { mapGetters } from 'vuex';

export default {
  name: 'AutomatedFilingTracking',
  props: ['automatedFiling'],
  computed: {
    ...mapGetters({
      automatedFilingTracking: 'orders/automatedFilingTracking'
    })
  },
  methods: {
    addSuggestedBreakToEmail,
    makeSentenceCase,
  },
  mounted () {
    this.$store.dispatch('orders/fetchAutomatedFilingTracking', {uuid: this.automatedFiling.automated_filing_uuid})
  }
};
</script>

<style lang="scss" scoped>
  table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
      minmax(max-content, max-content)
      minmax(auto, max-content)
      minmax(min-content, max-content)
      minmax(min-content, max-content)
      minmax(min-content, max-content)
      minmax(auto, max-content)
      minmax(auto, max-content)
      minmax(auto, max-content)
      minmax(auto, max-content)
      minmax(6em, auto)
      minmax(6em, auto);
    overflow-x: auto;
  }
  thead,
  tbody,
  tr {
    display: contents;
  }
  .modal-dialog {
    max-width: 96vw;
  }
</style>
