<template>
  <div>
    <h4 class="tex-start mt-4">Retry Callback of an Automated Filing</h4>
    <section class="row mt-6 d-flex justify-content-start">
      <div class="col-6 col-lg-6">
        <section class="row mt-5">
          <div class="col-8 col-lg-8">
            <label for="uuid">Automated Filing UUID</label>
            <ZgInput type="email" class="form-control form-control-sm" id="uuid" placeholder="Automated Filing UUID"
              v-model="automatedFilingUuid" />
          </div>
          <div class="col-4 col-lg-4 d-flex align-items-end">
            <ZgButton class="btn zg-text-sm btn-light btn-outline-success" @click="sendCallback" type="submit"
              :disabled="!isValidCallback()">
              <template v-slot:title> <i class="fas fa-pencil-alt zg-icon-text"></i> Send</template>
            </ZgButton>
            <ZgButton class="btn zg-text-sm btn-light btn-outline-info" style="margin-left:.5em" @click="resetInput">
              <template v-slot:title> <i class="fas fa-eraser zg-icon-text"></i> Clear</template>
            </ZgButton>
          </div>
        </section>
        <section class="row mt-4">
          <span class="col-11" content="" v-tippy>
            <label for="callbackPayload">Callback Payload</label>
            <textarea class="form-control" id="callbackPayload" rows="10" v-model="callbackPayload" />
          </span>
        </section>
        <section class="row mt-4 justify-content-start">
          <span class="col-6">
            <a href="https://box.scrapehero.com/pb/" target=”_blank”>Go to Scrapehero Private Bin</a>
          </span>
        </section>
      </div>
    </section>
    <div class="text-center" v-if="isLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import ZgInput from '@/components/common/Generics/ZgInput.vue';
import messages from '@/assets/messages';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'retryCallback',
  components: {
    ZgButton,
    ZgInput,
  },
  data() {
    return {
      isLoading: false,
      automatedFilingUuid: '',
      callbackPayload: '',
      payloadObject: {}
    };
  },

  methods: {
    ...mapActions({
      sendAutomatedFilingCallback: 'automation/sendAutomatedFilingCallback',
      setAlertMessage: 'setAlertMessage',
    }),
    resetInput() {
      this.automatedFilingUuid = "";
      this.callbackPayload = "";
    },
    sendCallback() {
      if (this.isValidJson(this.callbackPayload)) {
        this.isLoading = true;
        this.automatedStatuses = [];
        this.sendAutomatedFilingCallback({
          automatedFilingUuid: this.automatedFilingUuid,
          callbackPayload: this.callbackPayload
        })
          .then((response) => {
            if (response) {
              this.setAlertMessage({ message: response })
            } else {
              this.setAlertMessage({ message: messages.sendCallbackMessage.error })
            }
          })
          .finally(() => { this.isLoading = false; });
      } else {
        this.setAlertMessage({ message: messages.invalidJsonMessage.error });
      }
    },
    isValidJson(payload) {
      try {
        JSON.parse(payload);
        return true;
      } catch (e) {
        return false;
      }
    },
    isValidCallback() {
      return (this.automatedFilingUuid !== '' && this.isValidJson(this.callbackPayload))
    }
  },

};
</script>

<style lang="scss" scoped>
.preview-box {
  max-height: 31.25rem;
}

.zg-icon-text {
  margin-right: .5em;
}
</style>
