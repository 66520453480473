<template>
  <div>
    <h4 class="mt-4">Automated Filings</h4>
    <section class="row mt-5 d-flex">
      <div class="col-4 col-lg-3 d-flex align-items-end">
        <input type="text" class="form-control me-2" v-model="searchUUID" placeholder="Search by UUID" style="width: 60%;" />
        <button class="btn btn-light btn-outline-primary " @click="searchByUUID">
          <i class="fas fa-search"></i>
          Search
        </button>
        <div v-if="isLoading" class="ms-2">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </section>
    <section class="mt-4" v-if="!isLoading && !automatedFilingFound && hasSearched">
      <h4 class="text-center">No automations found</h4>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { hasPermissionMixin, automatedFilingMixin } from '@/components/mixins';

export default {
  name: 'AutomatedFilings',

  mixins: [hasPermissionMixin, automatedFilingMixin],

  data() {
    return {
      isLoading: false,
      automatedFilingFound: false,
      hasSearched: false,
      searchUUID: '',
    };
  },

  methods: {
    ...mapActions('orders', ['fetchOrderTasks']),
    ...mapActions('automation', ['fetchAutomatedFiling']),
    searchByUUID() {
      this.isLoading = true;
      this.hasSearched = true;
      this.automatedFilingFound = false;
      this.fetchAutomatedFiling(this.searchUUID)
        .then((response) => {
          if (Object.keys(response).length > 0) {
            const automatedFiling = response;
            this.fetchOrderTasksAndRedirect(automatedFiling);
          } else {
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async fetchOrderTasksAndRedirect(automatedFiling) {
      const orderTasks = await this.fetchOrderTasks({ uuid: automatedFiling.order_uuid });
      const matchingOrderTask = orderTasks.find((task) => task.order_task_type === automatedFiling.filing_type);
      if (matchingOrderTask) {
        this.redirectToFilingDetails(automatedFiling, matchingOrderTask.order_task_uuid);
      } else {
        this.isLoading = false;
        this.automatedFilingFound = false;
      }
    },

    redirectToFilingDetails(automatedFiling, orderTaskUuid) {
      const redirectUrl = `/order/${automatedFiling.order_uuid}/service/${orderTaskUuid}`;
      this.$router.push(redirectUrl);
    },
  },
};
</script>
