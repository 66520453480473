<template>
    <div>
      <h4 class="text-center mt-4">Automated Filing Status</h4>
      <section class="row mt-5 d-flex justify-content-end">
        <div class="col-4 col-lg-3">
          <label for="service-type">Service type</label>
          <select id="service-type" class="form-control" v-model="serviceType">
            <option value="llc_formation">LLC Formation</option>
            <option value="llc_annual_report">Annual Report</option>
          </select>
        </div>
        <div class="col-4 col-lg-3">
          <label for="started-at">Started at</label>
          <select id="started-at" class="form-control" v-model="startedAt">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_7_days">Last 7 days</option>
            <option value="this_month">This month</option>
            <option value="this_year">This year</option>
          </select>
        </div>
        <div class="col-4 col-lg-2 d-flex align-items-end">
          <button class="btn btn-light btn-outline-primary " @click="fetchAutomatedStatus">
            <i class="fas fa-search"></i>
            Search
          </button>
        </div>
      </section>
      <div class="alert alert-dark mt-4 d-flex" role="alert">
        <p class="mb-0 p-0">Completion times are based only on successful processes</p>
      </div>
      <section class="row mt-4">
        <span class="col-3">Current status by state:</span>
        <span class="col-3" content="Robot can be used" v-tippy>
          <i class="fas fa-check-circle text-success"></i>
          Running smoothly
        </span>
        <span class="col-3"  content="Robot having some issues" v-tippy>
          <i class="fas fa-exclamation-triangle text-warning"></i>
          Intermitent issues
        </span>
        <span class="col-3"  content="Stop using this robot" v-tippy>
          <i class="fas fa-times-circle text-danger"></i>
          Service disruption
        </span>
      </section>
      <div class="text-center" v-if="isLoading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <section class="row mt-4 d-flex flex-row" v-if="automatedStatuseSortedByState.length">
        <AutomationStatusItem class="col-12 col-md-6 col-lg-4"
          v-for="(automatedStatus, index) in automatedStatuseSortedByState" :key="index" :automatedStatus="automatedStatus"
          :serviceType="serviceType" :startedAt="startedAt"/>
      </section>
      <section class="mt-4" v-if="!automatedStatuseSortedByState.length && !isLoading">
        <h4 class="text-center">No automations available</h4>
      </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AutomationStatusItem from './automation-status/automation-status-item';

export default {
  name: 'AutomationStatus',

  components: {
    AutomationStatusItem
  },

  data() {
    return {
      isLoading: true,
      automatedStatuses: [],
      serviceType: 'llc_formation',
      startedAt: 'today'
    };
  },

  computed: {
    automatedStatuseSortedByState() {
      const automatedStatuses = [...this.automatedStatuses];
      return automatedStatuses.sort((a, b) => a.state.localeCompare(b.state));
    }
  },

  methods: {
    ...mapActions('automation', ['fetchAutomatedFilingStatus']),
    fetchAutomatedStatus() {
      this.isLoading = true;
      this.automatedStatuses = [];
      this.fetchAutomatedFilingStatus({
        service_type: this.serviceType,
        period: this.startedAt
      })
        .then((response) => { this.automatedStatuses = response !== undefined ? response : []; })
        .finally(() => { this.isLoading = false; });
    }
  },

  mounted() {
    this.fetchAutomatedStatus();
  }
};
</script>
