<template>
    <div>
      <h4 class="mt-4">Automated Filings</h4>
      <section class="row mt-5 d-flex">
        <div class="col-4 col-lg-2">
          <label for="service-type">Service type</label>
          <select id="service-type" class="form-control" v-model="serviceType">
            <option value="llc_formation">LLC Formation</option>
            <option value="llc_annual_report">Annual Report</option>
          </select>
        </div>
        <div class="col-4 col-lg-2">
          <label for="started-at">Started at</label>
          <select id="started-at" class="form-control" v-model="startedAt">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_7_days">Last 7 days</option>
            <option value="this_month">This month</option>
            <option value="this_year">This year</option>
          </select>
        </div>
        <div class="col-4 col-lg-2">
          <label for="state">State</label>
          <select id="state" class="form-control" v-model="state">
            <option value="">All</option>
            <option :value="state" v-for="state in statesWithAutomation" :key="state">{{ state }}</option>
          </select>
        </div>
        <div class="col-4 col-lg-2">
          <label for="status">Status</label>
          <select id="status" class="form-control" v-model="status">
            <option value="">All</option>
            <option value="success">Success</option>
            <option value="in_progress">In progress</option>
            <option value="non_success">Non success</option>
          </select>
        </div>
        <div class="col-4 col-lg-2">
          <label for="started-by">Started by</label>
          <select id="started-by" class="form-control" v-model="startedBy">
            <option value="">All</option>
            <option :value="userEmail"> {{ userEmail }}</option>
          </select>
        </div>

        <div class="col-4 col-lg-2 d-flex align-items-end">
          <button class="btn btn-light btn-outline-primary " @click="search">
            <i class="fas fa-search"></i>
            Search
          </button>
        </div>
      </section>
      <section class="table-responsive mt-4">
        <table class="table table-sm">
          <thead>
            <tr>
              <th></th>
              <th>Entity Name</th>
              <th>State</th>
              <th>Status</th>
              <th>Time to complete</th>
              <th>Started by</th>
              <th>Attempts</th>
              <th>Order task</th>
              <th>Order task status</th>
              <th>Salesforce Link</th>
              <th>Tracking</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="12">
                <div class="text-center" v-if="isLoading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-for="(automatedFiling, index) in automatedFilingsSorted" :key="index">
              <td>
                <input v-if="canBeSelected(automatedFiling)" type="radio" name="automated-filing" @click="selectFiling(automatedFiling, $event)">
              </td>
              <td>{{ automatedFiling.entity_name }}</td>
              <td>{{ automatedFiling.jurisdiction }}</td>
              <td class="automated-filing-field">{{ automatedFiling.status }}</td>
              <td>{{ getDuration(automatedFiling.completion_time) }}</td>
              <td v-html="startedByUsername(automatedFiling.last_tracking.started_by)"></td>
              <td>{{ automatedFiling.num_tries }}</td>
              <td><a :href="'/order/' + automatedFiling.order_task.order + '/service/' + automatedFiling.order_task.order_task_uuid" target="_blank">View</a></td>
              <td><span class="badge bg-info">{{ automatedFiling.order_task.order_task_status }}</span></td>
              <td><a :href="manifest.clients.salesforce.host + 'lightning/r/' + automatedFiling.order_task.customer_asset_sf_id + '/view'" target="_blank">View in Salesforce</a></td>
              <td>
                <button class="btn btn-link pt-0" @click="automatedTracking(automatedFiling)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <AutomatedFilingTracking v-if="showTracking"  @close="showTracking = false" :automatedFiling="automatedFilingTracking"/>
      <div class="d-flex flex-row mt-4 mb-5">
        <div class="col-6">
          <button :disabled="!isAutomationAdmin || !selectedFiling || isLoading" class="btn btn-outline-primary" @click="startAutomatedFiling">
            Start automated filing
            <i class="fa fa-user-lock" :content="`${this.adminMessage}`" v-tippy v-if="!isAutomationAdmin" />
            <i class="fa fa-info-circle" content="Please select an Automation task" v-tippy v-else-if="!selectedFiling" />
          </button>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button class="btn btn-outline-primary" @click="previousPage" :disabled="!automatedFilings.previous">Previous</button>
          <span class="mx-3 pt-2">
            <p class="p-0" v-if="totalPages">{{ page }} - {{ totalPages }}</p>
            <p class="p-0" v-else> - </p>
          </span>
          <button class="btn btn-outline-primary" @click="nextPage" :disabled="!automatedFilings.next">Next</button>
          <p>Total results: {{ this.automatedFilings.count }}</p>
        </div>
      </div>
      <section class="mt-4" v-if="!automatedFilingsSorted.length && !isLoading">
        <h4 class="text-center">No automations available</h4>
      </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { hasPermissionMixin, automatedFilingMixin } from '@/components/mixins';
import { AUTOMATION_STATUSES } from '@/assets/constants/automations';
import AutomatedFilingTracking from'@/components/common/automated-filing-tracking.vue';
import manifest from '@/manifest';
import authService from '../../../auth/AuthService';

export default {
  name: 'AutomatedFilings',

  components: {
    AutomatedFilingTracking
  },

  mixins: [hasPermissionMixin, automatedFilingMixin],

  data() {
    return {
      isLoading: true,
      showTracking: false,
      automatedFilings: {},
      page: 1,
      serviceType: 'llc_formation',
      startedAt: 'today',
      state: '',
      status: '',
      startedBy: authService.getLoggedInEmail(),
      selectedFiling: null,
      manifest,
      AUTOMATION_STATUSES
    };
  },

  computed: {
    automatedFilingsSorted() {
      if (this.automatedFilings.results) {
        return [...this.automatedFilings.results]
      }
      return [];
    },
    userEmail() {
      return authService.getLoggedInEmail();
    },
    statesWithAutomation() {
      return [
        'TX', 'GA', 'NY', 'MI', 'NJ', 'VA', 'IL', 'OH', 'CA', 'FL', 'CO'
      ]
    },
    totalPages() {
      return Math.ceil(this.automatedFilings.count / 10);
    },
  },

  methods: {
    ...mapActions('automation', ['fetchAutomatedFilingList']),
    getAutomatedFilingList() {
      this.isLoading = true;
      this.automatedFilings = [];
      this.fetchAutomatedFilingList({
        page: this.page,
        service_type: this.serviceType,
        started_at: this.startedAt,
        jurisdiction: this.state,
        status: this.status,
        started_by: this.startedBy
      })
        .then((response) => { this.automatedFilings = response !== undefined ? response : {}; })
        .finally(() => { this.isLoading = false; });
    },
    canBeSelected(automatedFiling) {
      const { status } = automatedFiling;
      return this.AUTOMATION_STATUSES.retryStatuses.includes(status) || Object.keys(automatedFiling).length === 0;
    },
    search() {
      this.page = 1;
      this.selectedFiling = null;
      this.getAutomatedFilingList();
    },
    nextPage() {
      this.page += 1;
      this.selectedFiling = null;
      this.getAutomatedFilingList();
    },
    previousPage() {
      this.page -= 1;
      this.selectedFiling = null;
      this.getAutomatedFilingList();
    },
    getDuration(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    selectFiling(automatedFiling, event) {
      if (this.selectedFiling && this.selectedFiling.automated_filing_uuid === automatedFiling.automated_filing_uuid) {
        event.target.checked = false;
        this.selectedFiling = null;
      } else {
        this.selectedFiling = automatedFiling;
      }
    },
    startAutomatedFiling() {
      this.isLoading = true;
      this.$store.dispatch('orders/startAutomatedFiling', {
        uuid: this.selectedFiling.order_task.order_task_uuid,
        test: false
      })
      .finally(() => {
        this.getAutomatedFilingList();
      });
      this.selectedFiling = null;
    },
    automatedTracking(automatedFilingTracking) {
      this.automatedFilingTracking = automatedFilingTracking;
      this.showTracking = true;
    },
    startedByUsername(email) {
      return email ? email.split('@')[0] : ''
    }
  },

  mounted() {
    this.serviceType = this.$route.query.service_type ? this.$route.query.service_type : 'llc_formation';
    this.startedAt = this.$route.query.started_at ? this.$route.query.started_at : 'today';
    this.state = this.$route.query.state ? this.$route.query.state : '';
    if (Object.keys(this.$route.query).length > 0) {
      this.startedBy = '';
    }
    this.getAutomatedFilingList();
  }
};
</script>

<style lang="scss" scoped>
  .table-responsive {
    height: 60vh;
  }
  .automated-filing-field {
    word-break: break-all;
  }
</style>
