<template>
  <div>
    <h4 class="tex-start mt-4">Generate Payload for Automated Filing</h4>
    <section class="row mt-6 d-flex justify-content-start">
      <div class="col-6 col-lg-6">
        <section class="row mt-5">
          <div class="col-8 col-lg-8">
            <label for="uuid">Order Task UUID</label>
            <ZgInput type="email" class="form-control form-control-sm" id="uuid" placeholder="Order Task UUID" v-model="orderTaskUuid" />
          </div>
          <div class="col-4 col-lg-4 d-flex align-items-end">
            <ZgButton class="btn zg-text-sm btn-light btn-outline-success" @click="generatePayload" type="submit">
              <template v-slot:title> <i class="fas fa-pencil-alt zg-icon-text"></i>  Generate</template>
            </ZgButton>
            <ZgButton class="btn zg-text-sm btn-light btn-outline-info" style="margin-left:.5em" @click="resetInput" >
              <template v-slot:title> <i class="fas fa-eraser zg-icon-text"></i> Clear</template>
            </ZgButton>
          </div>
        </section>
        <section class="row mt-4">
          <span class="col-11"  content="" v-tippy>
              <label for="payload">Payload</label>
              <textarea class="form-control" id="payload" rows="10" v-model="payloadResult"/>
          </span>
        </section>
        <section class="row mt-4 justify-content-start">
          <span class="col-4"  content="Copy to Clipboard" v-tippy>
            <ZgButton class="btn zg-text-sm btn-light btn-outline-primary" :disabled="!payloadExists()" 
                     @click="copyToClipboard" >
              <template v-slot:title><i class="fas fa-clipboard zg-icon-text"></i> Copy to Clipboard</template>
            </ZgButton>
          </span>
          <span class="col-4"  content="Download the payload" v-tippy>
            <ZgButton class="btn zg-text-sm btn-light btn-outline-info" :disabled="!payloadExists()"  
                      @click="downloadPayload" >
              <template v-slot:title><i class="fas fa-download zg-icon-text"></i> Download</template>
            </ZgButton>
          </span>
        </section>
        <section class="row mt-4 justify-content-start">
          <span class="col-6">
              <a href="https://box.scrapehero.com/pb/" target=”_blank”>Go to Scrapehero Private Bin</a>
          </span>
        </section>
        </div>
        <div class="col-6 col-lg-6">
          <section class="row mt-5">
            <b>Preview:</b> 
            <div class="col-12 col-lg-12 overflow-auto">
              <p><vue-json-pretty class="preview-box" :data="payloadResultObject"></vue-json-pretty></p>
            </div>
          </section>
        </div>
    </section>
    <div class="text-center" v-if="isLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import ZgInput from '@/components/common/Generics/ZgInput.vue';
import { generateTemporalDocument } from '@/store/modules/utils';
import messages from '@/assets/messages';
import 'vue-json-pretty/lib/styles.css';

export default {
name: 'generatePayload',
components: {
  VueJsonPretty,
  ZgButton,
  ZgInput,
},
data() {
  return {
    isLoading: false,
    orderTaskUuid:'',
    payloadResult:'',
    payloadResultObject:{}
  };
},

methods: {
  ...mapActions({
    generateAutomationPayload: 'automation/generateAutomationPayload',
    setAlertMessage: 'setAlertMessage',
  }),
  copyToClipboard() {
    let payload = this.payloadResult;
    navigator.clipboard.writeText(payload);
    this.setAlertMessage({ message: messages.copyPayloadInteractionMessage.success })
    return;
  },
  resetInput() {
    this.orderTaskUuid = "";
  },
  generatePayload() {
    this.isLoading = true;
    this.automatedStatuses = [];
    this.generateAutomationPayload(this.orderTaskUuid)
      .then((response) => {
        if (response) {
          this.payloadResultObject = response;
          this.payloadResult = JSON.stringify(response);
        } else {
          this.setAlertMessage({ message: messages.generateAutomationPayloadMessage.error })
        }
      })
      .finally(() => { this.isLoading = false; });
  },
  async downloadPayload(){
    let uuid = this.orderTaskUuid;
    let payload = this.payloadResult;
    let format = 'json';
    let filename = `${uuid}_payload`;
    await generateTemporalDocument(payload, format, filename)
    this.setAlertMessage({ message: messages.downloadPayloadInteractionMessage.success })
    return;
  },
  payloadExists(){
    return (this.orderTaskUuid !== '' &&  this.payloadResult !== '')
  }
},

};
</script>
<style lang="scss" scoped>
.preview-box{
  max-height: 31.25rem;
}
.zg-icon-text{
  margin-right: .5em;
}
</style>

